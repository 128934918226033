import "@fortawesome/fontawesome-svg-core/styles.css";
import { library, config } from "@fortawesome/fontawesome-svg-core";

import {
  faHackerrank,
  faGithub,
  faTwitter,
  faJs,
  faHtml5,
  faCss3,
  faReact,
  faNode,
  faLinkedinIn,
  faBootstrap,
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowRightLong,
  faCode,
  faHandshake,
  faPaperPlane,
  faMoon,
  faSun,
  faEdit,
  faEnvelope,
  faPhone,
  faWindowMaximize,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHackerrank,
  faArrowRightLong,
  faBootstrap,
  faGithub,
  faLinkedinIn,
  faTwitter,
  faCode,
  faJs,
  faHandshake,
  faPaperPlane,
  faHtml5,
  faCss3,
  faReact,
  faNode,
  faMoon,
  faSun,
  faEdit,
  faEnvelope,
  faPhone,
  faWindowMaximize,
  faLocationDot
);

config.autoAddCss = false;
