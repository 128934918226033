import React from "react";
import svgRect from "../../static/home_rect.svg";
import Button from "../common/Button";
import { Card, CardIcon, CardText, CardTitle } from "../common/Card";
import Flex from "../common/Flex";
import IconLink from "../common/IconLink";
import PageHeader from "../common/PageHeader";
import { HeroCard } from "./HeroCard";
import { HomeWrapper, Intro } from "./Home.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typewriter from "typewriter-effect";

import leetcode from "../../static/leetcode.svg";


const ThingsILove = () => (
  <Flex justify="space-between" align="center">
    <Card>
      <CardIcon>
        <FontAwesomeIcon icon="code" />
      </CardIcon>
      <CardTitle>FrontEnd</CardTitle>
      <CardText>
        I'm more front end focused and love to work with Reactjs as well as pure
        HTML, CSS.
      </CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon  icon={["fab", "js"]} />
      </CardIcon>
      <CardTitle>Backend</CardTitle>
      <CardText>
        Passionate about backend development, crafting APIs, managing databases, and optimizing server-side processes for scalable web apps.
      </CardText>
    </Card>


    <Card>
      <CardIcon>
        <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAFTElEQVR4nO2b228VRRzHZxFae0GDcKBgiSJeYlITNTaR2JiIqREwwUCiGB80AoLEhEQfpBp8IJL4BxhM1Fes+mLwgvpCvFSBFGpaLTcjGGkkgIpaK62X8/Fhf5uzPexlZmf2bNHzTZpJOt/f7/ed2dmZ38zsUaqO4oCgSA3Tigw+FVDvgKIF1FHHRQpgyrw+NloyGQL3KqUGgPlZA7sCMFcp1Q+srFXAacABWcK/EgE6douBx4HXgQHgFFAWP7+JrzeAzcBiTZ9zxQ7gYM1GJVAKBR4CGmJ4M4AHgU8xx37gYWBGjO8GiW30IJxBOmEQWB9TvxQYDjVoFNgJbAA6gSuAJsAD2oDbZIS8CZwL2R0BlsfEWCedUNvGhwRc8OSBRuC1UAO+BR4Fmg38tkhnHAn5eRVo0dFQGID5wF4RfB54ykagDPMtwJj47AfmudTsDMAc4JAIPQrc7ND3TcCJkO9ihnwcZMjuF4FDQCmHGCXgS4lxEGhyHSMzgB0i7Fha4/GXru0ygf4uw3sA6AHaUmzbZE4BeMVtKzIC6MZfz8+nDXtgNf6aH4cfgftTfHQA48LvdtsaQ+AnRcFS93QKdzWVxOc94G5gpvzdI/8D+Ae4L8VXj3APA5e4bZUBgFUi5DugMYFXAn4V7pYE3nPC+QWYk8CbDhwX7kO27cgMoE9ERCZDId424b2j4TMYCT0pvE3C6zPV7QTAAhnSY8DMFG4we9+l4bdbuP0pvCb8SbQMLDLVbw1gowh9S4MbTHyJHSXcWcL9SYP7tnA36uquhs3uaYmUe9KInudd5vkY1fAbnBLraPtQyi4NbiRsOuAGKYcsfEShU8pvNLjDUt7oWEM68NdscJyWAh+I32c1uPOEe9alhsD5JETUj0pVq8OYz4jPc8BsDX6L8MdN9euISeuAP6TKyXYUeERm9L+BZZo2QQdMmOp3IfiM+G534Gs58Jf422RgtyC3V0AjeLDvv8PSTyswIr62GtreKXYDWePbrALBLG2779+qlLpSKbVXKfWCoW2HlIctNZgDWCu9v8vCR7NMpmXglgz274qGzIlQZlBJhUfRyPBifDwgDTDO5/F3kWNYpsKZXwHP835QSvUppVqVUo9ldBPs/Xsz2K5VSjUrpb7wPO9Exvh2AFbKEzxOwnY4wb5f7DvT2ZPsGvG34ABrTOM6A/6ByNciZFsG+2ApNTpDpLK9PkSRByIipgv/FGfCdCIL5Smegc2twJ9it9RccQ4AXhJBIzhIjBLitIdyhh15xTGGvJMfi7DBrKtCSoxZVO4C92WZc3KFCAwuRnahcVMbjH8N3qVUjt+G0dgoFQLgGuCsCH1Rg6/bAS8L9TRwtRu1DkDE1TV+fj6Bn6AYLW8xMbqk8eNE7Dso6nKUyvX4uoi67SL6M8sYHpUrt+cj6tdTxPU4kz+QGKx+Cvhp6impX2URZ01odWmpqmuQ2ODnIrXpBDQ/kQGeFM4nFrH2iY8NMfXhB1HTT2RWSMDYXgdm4ycsZeCqDDGuD737lyfwSvJAVpjGsIJObwO7pRFPZPC/WWxTt9s2T95mN1jWoH0k5ZJEVjRur/JhqyUSeb8zR6XsSGRFI7A55khL7QEskmFsfGgJ/Cy2xvPHlAGwB3u8n6dG7W1oFoCb83jP83LTOT0vx2FkbYCrDkzClPniuyjUO6BoAUWj3gFFCygaeXfAiFIXXlUTcU4AfF5NkqqTOWvMD8Ay4GRMgtMe4i2M4XyP//Oc/w6AXmncbvxj7oVUPovZWbS+3AFch3+4WY3TaP5W6KIHcC3+D6TOyF/v/6bxUw3/AiFy7DPbFxHQAAAAAElFTkSuQmCC' alt="" />
      </CardIcon>
      <CardTitle>Problem Solving</CardTitle>
      <CardText>
        I enjoy solving problems, troubleshooting issues, and finding solutions.
      </CardText>
    </Card>
  </Flex>
);

const Home = () => {
  return (
    <HomeWrapper id="home">
      <img className="svg-rect" src={svgRect} alt=""></img>

      <Intro>
        <div className="home__text">
          <p>Hello, I’m</p>
          <h1>SHANTANU BOSE</h1>
          <div className="adjust">
            <Typewriter
              options={{
                strings: ["FULL STACK DEVELOPER", "JAVASCRIPT ENTHUSIAST"],
                autoStart: true,
                loop: true,
                delay: 150,
                deleteSpeed: 100,
                pauseFor: 1000,
                skipAddStyles: true,
              }}
            />
          </div>

          <div className="home__CTA">
            <Button
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1VArsvUT_LafCWrs2bzO_48dEym7CpUYG/view?usp=sharing"
                )
              }
              target="_blank"
              rel="noreferrer"
              className="cta"
              as="a"
              href=""
              download="ShantanuBose_Resume"
            >
              Download Resume
            </Button>

            <div className="home__social">
              <IconLink
                label="github"
                icon={["fab", "github"]}
                href="//github.com/shantanubose01"
              />
              <IconLink
                label="linkedin"
                icon={["fab", "linkedin-in"]}
                href="//linkedin.com/in/shantanubose01"
              />
              <IconLink
                label="hackerrank"
                icon={["fab", "hackerrank"]}
                href="//hackerrank.com/shantanubose90"
              />
              <a
                href="//leetcode.com/ShantanuBose/"
                target="_blank"
                aria-label="leetcode"
                title="leetcode"
                rel="noreferrer"
              >
                <img
                  src={leetcode}
                  alt="leetcode icon"
                  height="22px"
                  style={{
                    marginTop: "1px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>

        <HeroCard />
      </Intro>
      {/* Things I LOVE */}
      <PageHeader style={{ marginBottom: 30 }}>
        Things I love <i className="fas fa-heart" />
      </PageHeader>
      <ThingsILove />
    </HomeWrapper>
  );
};

export default Home;
