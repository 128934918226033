import About from "./components/about/About";
import Certifications from "./components/certifications/Certifications";
import Concepts from "./components/miniJsProjects/MiniJsProjects";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import GithubOverview from "./components/github/GithubOverview";
import Home from "./components/home/Home";
import Projects from "./components/projects/Project";
import Skills from "./components/skills/Skills";
import SEO from "./SEO";

function App() {
  window.document.title='Shantanu Bose'
  return (
    <>
      <SEO />
      <Home />
      <About />
      <Experience />
      <Skills />
      <Projects />
     
      

      <Contact />
    </>
  );
}

export default App;
